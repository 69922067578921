import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import BathRenovation from "./components/BathRenovation.jsx";
import UserInfoConponent from "./components/userInfo.js";
import LoginLink from "./components/LoginLink.js";
import { Context as AppContext } from "./context/appContext.js";
// import OAuthCallback from "./components/oauthCallback.js";
import NavBar from "./components/navBar.js";
import Logout from "./components/logOut.js";
import InvoicesView from "./components/InvoicesView.js";
import InvoicePreview from "./components/invoicePreview.js";
import CustomersComponent from "./components/CustomersComponent.js";
import PaymentScreen from "./components/PaymentScreen.js";
import Dashboard from "./components/Dashboard.js";
import MerchantSignUp from "./components/MerchantSignUp.js";
import TermsPage from "./pages/terms.js";
import PrivacyPage from "./pages/privacy.js";
import CookiePolicyPage from "./pages/cookie.js";
import CookieConsent from "./components/CookieConsent.js";
import OpenInvoices from "./components/OpenInvoices.js";
// import MakePayment from "./components/MakePayment.js";
import MakePaymentSdk from "./components/MakePaymentSdk.js";
import MerchantLogComponent from "./components/MerchantLogComponent.js";
import HomePage from "./components/HomePage.js";
import CMSDashboard from "./components/cms/Dashboard";
import PostEditor from "./components/cms/PostEditor";
import CMSRoutes from "./components/cms/CMSRoutes";
import { AuthProvider } from "./context/authContext";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import PrivateRoute from "./components/auth/PrivateRoute";
import PostList from './components/blog/PostList';
import PostDetail from './components/blog/PostDetail';
import PageContent from './components/pages/PageContent';

const App = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { state, checkAuthCookie, setAuthToken } = useContext(AppContext);
  console.log(state);
  // const params = new URLSearchParams(window.location.search);
  // const authToken = params.get('authToken');

  useEffect(() => {
    // checkAuthCookie();
    let params = new URLSearchParams(window.location.search);
    let authToken = params.get("authToken");
    if (authToken) {
      // console.log("Auth Token found:", authToken);
      setAuthToken(authToken).then(() => {
        window.location.href = "/";
      });
    }
    checkAuthCookie();
  }, []);

  if (!state.authToken) {
    return (
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginLink />} />
            <Route path="/cms/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route
              path="/make/payment/:invoiceId"
              element={<MakePaymentSdk />}
            />
            <Route
              path="/cms/*"
              element={
                <PrivateRoute requireCMS={true}>
                  <CMSRoutes />
                </PrivateRoute>
              }
            />
            <Route path="/blog" element={<PostList />} />
            <Route path="/blog/:slug" element={<PostDetail />} />
            <Route path="/pages/:slug" element={<PageContent />} />
          </Routes>
          <CookieConsent />
        </Router>
      </AuthProvider>
    );
  }
  if (!state.merchant) {
    return <MerchantSignUp />;
  }
  if (!state.devices || state.devices.length === 0) {
    return <MerchantLogComponent />;
  }

  if (window.location.pathname.startsWith("/make/payment/")) {
    return <MakePaymentSdk />;
  }

  return (
    <AuthProvider>
      <Router>
        <div className="container-fluid">
          <div className="row">
            <NavBar />
          </div>
          <div className="row">
            {/* <main className="col-md-10 ms-sm-auto col-lg-10 "> */}
            <main className="w-100 vh-100">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/payments" element={<PaymentScreen />} />
                <Route path="/customers" element={<CustomersComponent />} />
                <Route path="/userinfo" element={<UserInfoConponent />} />
                <Route path="/invoices" element={<InvoicesView />} />
                <Route
                  path="/invoice/:invoiceId"
                  element={<InvoicePreview />}
                />
                <Route path="/logout" element={<Logout />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/cookie-policy" element={<CookiePolicyPage />} />
                <Route path="/openInvoices" element={<OpenInvoices />} />
                {/* <Route path="/make/payment/:invoiceId" element={<MakePayment />} /> */}
                <Route
                  path="/cms/*"
                  element={
                    <PrivateRoute requireCMS={true}>
                      <CMSRoutes />
                    </PrivateRoute>
                  }
                />
                <Route path="/blog" element={<PostList />} />
                <Route path="/blog/:slug" element={<PostDetail />} />
                <Route path="/pages/:slug" element={<PageContent />} />
              </Routes>
              <CookieConsent />
            </main>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
